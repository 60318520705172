/* About us page csss */
.about-box{
    background: var(--bggrey);
  }
  .progress-bar{
    background-color: var(--orange);
  }
  .progress{
    height: 0.7rem;
    border-radius: 25px;
  }

.about-box .carousel-indicators li{
    height: 30px;
    width: 30pox;
    background-color: var(--grey);
    border-radius: 100%;
}
.why-choose {
    text-align: center;
    background: #fff;
    margin-top: 20px;
}
.why-box{
    padding: 15px;
}

.why-choose .col-md-4:first-child .why-box {
    border-bottom: 1px solid #eee;
}

.why-choose .col-md-4:nth-child(2) .why-box {
    border-bottom: 1px solid #eee;
    border-right: 1px solid #eee;
    border-left: 1px solid #eee;
}
.why-choose .col-md-4:nth-child(3) .why-box  {
    border-bottom: 1px solid #eee;
}
.why-choose .col-md-4:nth-child(5) .why-box  {
    border-right: 1px solid #eee;
    border-left: 1px solid #eee;
}

.why-box  i {
    font-size: 35px;
    color: var(--grey);
    transition: .4s;
}
.why-box h5 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 8px;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: var(--orange) !important;
}
.owl-theme .owl-dots .owl-dot span {
    width: 15px!important;
    height: 15px!important;
    margin: 0 5px!important;
    background: #d7d7d7!important;
    border-radius: 50%!important;
    transition: .4s!important;
}
.owl-item .title-box p{
    margin-left: 0;
}