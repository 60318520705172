/* Navbar css */

a.navbar-brand {
    color: var(--white);
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 2rem;
    font-family: 'zeroesone';
    text-transform: uppercase;

}
a.navbar-brand img {
    width: 145px;
}
nav.fixed-top .logoblack, nav.fixed-top.is-sticky .logowhite{
display: none;
transition: 0.5s;
}
nav.fixed-top .logowhite{
    display: block;
    transition: 0.5s;
}
nav.fixed-top.is-sticky .logoblack{
    display: block;
    transition: 0.5s;
}
nav.fixed-top {
    position: fixed;
    width: 100%;
    max-width: 100%;
    left: 0;
    top: 0;
    padding: 0.7rem 0.5rem;
    background-color: initial!important;
    z-index: 999;
}
a.navbar-brand:hover{
    color: var(--white);
}
.fixed-top.is-sticky {
    
    background-color: #fff!important;
    box-shadow: 0 7px 13px 0 rgba(0,0,0,.1);
    -webkit-animation: fadeInDown .5s ease-in-out 0s 1 normal none running;
    animation: fadeInDown .5s ease-in-out 0s 1 normal none running;
}
a.navbar-brand span{
    color: var(--orange);
}
nav a,  .navbar-nav .nav-link {
    cursor: pointer;
    color:var(--white);
    transition: 0.5s;
   
}
.is-sticky .navbar-nav .nav-link, .is-sticky  a.navbar-brand{
    color:var(--black);
    transition: 0.5s;
}
.navbar-toggler{
    z-index: 9999;
}
nav a:hover,  .navbar-nav .nav-link:hover, .navbar-nav .nav-link.active{
    color: var(--orange);
}
nav{
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
}
.navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.8rem;
    padding-left: 0.8rem;
}
@keyframes fadeInDown{
0% {
    -webkit-transform: translate3d(0,-100%,0);
    opacity: 0;
    transform: translate3d(0,-100%,0);
}
100% {
    -webkit-transform: translateZ(0);
    opacity: 1;
    transform: translateZ(0);
}
}
.navbar-expand-md .navbar-nav .nav-item:last-child .nav-link{
    padding-right: 0;
}

@media only screen and (max-width: 767px){
   .navbar-toggler .navbar-toggler-icon {
        background-image: url(../images/menu.svg);
    }
    a.navbar-brand img {
        width: 81px;
    }
    .navbar-toggler{
        padding-right: 0;
    }
  
    nav.navbar .menu-ul{
        background-color: var(--white);
        padding: 60px 0 15px;
        position: fixed;
        top: 0;
        right: 0;
        display: block !important;
        width: 60% !important;
        text-align: center;
        bottom: 0;
        -webkit-transition: all 0.5s ease 0s;
        -moz-transition: all 0.5s ease 0s;
        -ms-transition: all 0.5s ease 0s;
        -o-transition: all 0.5s ease 0s;
        transition: all 0.5s ease 0s;
        -webkit-transform: translateX(110%);
        -moz-transform: translateX(110%);
        -ms-transform: translateX(110%);
        -o-transform: translateX(110%);
        transform: translateX(110%);
        height: auto !important;
        overflow: auto;
        border-radius: 0;
        border-left: 1px solid var(--orange);
    }
    nav.navbar .menu-ul.show {
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
        -o-transform: translateX(0%);
        transform: translateX(0%);
    }
    nav.navbar .menu-ul:after {
        width: 50px; 
        height: 50px;
        background: #f6994b;
        position: absolute;
        content: "";
        top: -10px;
        left: -10px;
        border-radius: 100%;
    }
    nav.navbar .menu-ul::before {
        width: 50px;
        height: 50px;
        background: #f6994b;
        position: absolute;
        content: "";
        bottom: -10px;
        right: -20px;
        border-radius: 100%;
    }
    .navbar-toggler span {
        position: relative;
        width: 25px;
        height: 2px;
        background-color: var(--orange);
        display: inline-block;
        vertical-align: middle;
        top: 50%;
        transform: translateY(-50%);
    }
    .navbar-toggler span::before, .navbar-toggler span::after {
        position: absolute;
        content: "";
        width: 17px;
        height: 2px;
        background-color:var(--orange);
        right: 0;
        transition: all 0.5s;
        animation: openCloseByeSpan 300ms linear forwards;
    }
    .navbar-toggler span::before{
        top: -7px;
    }
    .navbar-toggler span::after {
        top: 7px;
    }
   
}