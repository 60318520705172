/* Portfolio Section Start  css*/
.ourportfolio{
    background: var(--white);
  }
  
.portfolio-box h5{
    margin-bottom: 0;
    text-transform: uppercase;
}
.portfolio-box{
    margin-bottom: 30px;
    
   
}

.portfolio-img{
    overflow: hidden;
}
.portfolio-img img{
    transform:scale(1);
    transition: 0.5s;
}
.portfolio-img:hover img{
    transform:scale(1.2);
    transition: 0.5s;
}
.ourportfolio .row .col-md-4:nth-child(4) .portfolio-box, .ourportfolio .row .col-md-4:nth-child(5) .portfolio-box, .ourportfolio .row .col-md-4:nth-child(6) .portfolio-box{
    margin-bottom: 0;
}